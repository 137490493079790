<template>
  <div>
    <validation-observer
      ref="TimerTaskRules"
      v-slot="{ invalid }"
    >
      <b-form>
        <div class="time-tracker">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="select-project"
            >
              <b-overlay
                :show="load_projects"
                rounded="sm"
              >
              <!-- #default="{ errors }" -->
                <validation-provider
                  name="Project"
                  rules="required"
                >
                  <v-select
                    id="project_id"
                    v-model="project_id"
                    name="project_id"
                    label="name"
                    :options="projects"
                    :reduce="(project) => project.id"
                    class="select-project-ticket"
                    placeholder="Select Project *"
                    :disabled="timerStart"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </validation-provider>
              </b-overlay>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="select-ticket"
            >
              <b-overlay
                :show="load_tickets"
                rounded="sm"
              >
              <!-- #default="{ errors }" -->
                <validation-provider
                  name="Ticket"
                  rules=""
                >
                  <v-select
                    id="ticket_id"
                    v-model="ticket_id"
                    hide-details
                    name="ticket_id"
                    label="number"
                    :options="tickets"
                    :reduce="(ticket) => ticket.id"
                    class="select-project-ticket"
                    placeholder="Select Ticket"
                    :disabled="timerStart"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </validation-provider>
              </b-overlay>
            </b-col>
          </b-row>
          <!-- input tag for eneter task -->
          <b-form-group class="mb-0">
            <!-- #default="{ errors }" -->
            <validation-provider
              name="Task"
              rules="required|max:2000"
            >
              <b-form-textarea
                v-model="task_name"
                class="select-item text-area time-log-text-area"
                name="task_name"
                type="text"
                :rows="$store.state.app.isFullScreenModal ?12 :3"
                maxlength="2000"
                placeholder="Describe your task details in max 2000 character *"
                @blur="saveTitle"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
            </validation-provider>
          </b-form-group>

          <div class="d-flex flex-wrap">
            <div
              v-for="(tag, tindex) in tags"
              :key="tindex"
              class="tag-timer-modal"
            >
              <span class="tag-timer-modal-span">{{ tag }} </span>
              <feather-icon
                v-if="!timerStart"
                icon="XIcon"
                size="10"
                class="x-icon-tag"
                @click="tags.splice(tindex, 1)"
              />
            </div>
          </div>
          <div class="timer-icons">
            <div class="d-flex align-items-center">
              <div
                v-if="!timerStart"
                class="new-play-icon"
              >
                <div>
                  <b-button
                    type="button"
                    :disabled="invalid || loader"
                    @click="startTimer()"
                  >
                    <div class="play-button">
                      <span class="d-flex align-items-center ml-0">
                        <b-icon icon="play-fill" />
                      </span>
                    </div>
                  </b-button>
                </div>
              </div>

              <div
                v-else
                class="new-play-icon-puse"
              >
                <div class="icons">
                  <b-button
                    type="button"
                    @click="stopTimer()"
                  >
                    <div class="play-button">
                      <span class="d-flex align-items-center ml-0">
                        <b-icon icon="pause-fill" />
                      </span>
                    </div>
                  </b-button>
                </div>
              </div>

              <span
                v-if="timerStart"
                class="start-time-hour"
              >
                {{ time | secondsInMinutes }}
              </span>
            </div>

            <div
              :class="
                timerStart ? 'tagButtonCursor feather-icon' : 'feather-icon'
              "
            >
              <div class="icons">
                <custom-dropdown
                  v-model="tags"
                  :options="tagOptions"
                  name="tag"
                  icon="TagIcon"
                  multiple
                  class="custom-drop"
                />
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// import FormSelect from '@/components/form/FormSelect.vue'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
import * as moment from 'moment/moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { eventBus } from '@/main'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import FeatherIcon from '../../../components/feather-icon/FeatherIcon.vue'

export default {
  name: 'TimerSection',
  components: {
    vSelect,
    // flatPickr,
    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    // FormSelect,
    customDropdown,
  },
  props: {
    taskList: {
      type: Array,
      required: true,
      default: () => [],
    },
    allTags: {
      type: Array,
      required: true,
      default: () => [],
    },
    projectsData: {
      type: Array,
      required: true,
      default: () => [],
    },
    loadProjects: {
      type: Boolean,
      required: true,
      default: false,
    },
    tab: {
      type: [Boolean,Number],
      required: true,
      default: false,
    },
  },
  data() {
    return {
      loader: false,

      task_log_id: null,
      task_id: null,
      time: 0,
      timer: null,
      timerStart: false,
      startDateTime: null,
      endDateTime: null,
      tickets: [],
      projects: [],
      all_tags: [],
      tags: (this.$store.state.app.dailyLogModelData && this.$store.state.app.dailyLogModelData.tags) || [],
      project_id: (this.$store.state.app.dailyLogModelData && this.$store.state.app.dailyLogModelData.project_id) || null,
      ticket_id: (this.$store.state.app.dailyLogModelData && this.$store.state.app.dailyLogModelData.ticket_id) || null,
      task_name: (this.$store.state.app.dailyLogModelData && this.$store.state.app.dailyLogModelData.task_name) || null,
      task_description: null,

      load_tickets: false,
      load_projects: false,
      edit_user_task_tags: [],
      searchOption: null,
    }
  },
  computed: {
    tagOptions() {
      const tags = []
      this.$store.state.app.tagList.forEach(element => {
        tags.push({
          value: element.name,
          label: element.name,
        })
      })
      return tags
    },
    allTagOptions() {
      if (this.searchOption) {
        const that = this
        return this.all_tags.filter(element => element.name
          .toLowerCase()
          .match(that.searchOption.toLowerCase()))
      }
      return this.all_tags
    },
  },
  watch: {
    project_id(nv, old) {
      if (nv) {
        this.getProjectTasksData(nv)
        this.$store.state.app.dailyLogModelData.project_id = nv
      }
      if (nv && old) {
        this.ticket_id = null
      }
    },
    taskList(nv) {
      if (nv) {
        if (!this.timerStart) {
          this.checkCurrentTask()
        }
      }
    },
    // All project Data with props
    projectsData(val) {
      this.projects = val
    },
    // loadProjects true and false
    loadProjects(val) {
      this.load_projects = val
    },
    ticket_id(nv) {
      this.$store.state.app.dailyLogModelData.ticket_id = nv
    },
    task_name(nv) {
      this.$store.state.app.dailyLogModelData.task_name = nv
    },
    tags(nv) {
      this.$store.state.app.dailyLogModelData.tags = nv
    },
    tab() {
      this.project_id = this.$store.state.app.dailyLogModelData.project_id
      this.task_name = this.$store.state.app.dailyLogModelData.task_name
      this.ticket_id = this.$store.state.app.dailyLogModelData.ticket_id
      this.tags = this.$store.state.app.dailyLogModelData.tags
      eventBus.$emit('closeCustomDropDown')
    },
  },
  mounted() {
    if (this.$store.state.app.dailyLogModelData.project_id) {
      this.getProjectTasksData(this.$store.state.app.dailyLogModelData.project_id)
    }
    if (this.$store.state.app.tagList.length == 0) {
      this.getTagsData()
    }

    /**
     * start new timer from exiting task
     */
    eventBus.$on('startExitingTaskTimer', data => {
      if (data) {
        setTimeout(() => {
          this.setEditData(data)
        }, 900)
      }
    })

    /**
     * stop timer
     */
    eventBus.$on('stopExitingTimer', taskID => {
      if (taskID) {
        this.stopTimer()
      }
    })

    /* clock in or clock out time log disable */
    eventBus.$on('autoTaskLogOnClockOut', data => {
      this.dayInOut = !!data

      if (data && this.task_id) {
        this.timerStart = false
        this.time = 0
        clearInterval(this.timer)
        this.endDateTime = moment(new Date())
          .add(this.time, 'seconds')
          .format('YYYY-MM-DD HH:mm:ss')
        this.clearForm()
      }
    })
  },
  destroyed() {
    eventBus.$off('dayInOutTaskTimeLogClose')
  },
  methods: {
    /**
     * Get Tasks data
     * @param {data}
     * @returns if timerstart then show toast(danger) other wise set modal variable and call startTimer()
     */
    setEditData(data) {
      if (this.timerStart) {
        this.$bvToast.toast('Timer was already started', {
          toaster: 'b-toaster-top-center',
          solid: true,
          variant: 'danger',
        })
        return false
      }
      this.ticket_id = data.ticket_id
      this.project_id = data.project_id
      this.task_name = data.task_name
      this.task_id = !data.task_log_id ? data.task_id : null
      this.task_log_id = data.task_log_id ? data.task_log_id : null
      this.task_date = data.date ? data.date : null
      this.start_time = data.start_time ? data.start_time : null
      this.end_time = data.end_time ? data.end_time : null
      if (data.user_task_tags && data.user_task_tags.length) {
        const tags = []
        data.user_task_tags.forEach(tag => {
          tags.push(tag.tag)
        })
        this.tags = tags
        this.edit_user_task_tags = data.user_task_tags
      }
      this.startTimer()
    },
    /**
     * Get Tasks data
     * @param {data}
     * @returns if timerstart then show toast(danger) other wise set modal variable and call startTimer()
     */
    checkCurrentTask() {
      let i = 0
      for (const element of this.taskList) {
        //
        // check task log
        // if (element.user_task_logs && element.user_task_logs.length) {
        //   for (const log of element.user_task_logs) {
        if (element && element.start_time && !element.end_time) {
          // this.task_log_id = log.id;
          this.task_id = element.id
          this.project_id = element.project_id
          this.task_name = element.title
          this.edit_user_task_tags = element.user_task_tags
          this.startDateTime = moment(
            `${element.date} ${element.start_time}`,
          ).format('YYYY-MM-DD HH:mm:ss')
          setTimeout(() => {
            this.ticket_id = element.project_task_id
          }, 100)

          // count time
          const now = moment(new Date()) // todays date
          const duration = moment.duration(now.diff(this.startDateTime))
          this.time = duration.asSeconds().toFixed(0)
          this.timer = setInterval(() => {
            this.time++
          }, 1000)
          this.timerStart = true
          this.$store.commit('app/UPDATE_MANANUAL_DISABLE_BUTTON_STATUS', true)

          // set tags
          if (element.user_task_tags && element.user_task_tags.length) {
            const tags = []
            element.user_task_tags.forEach(tag => {
              tags.push(tag.tag)
            })
            this.tags = tags
          }
          i++
          break
        }
        //   }
        // }
        if (i == 1) {
          break
        }
      }
    },
    startTimer() {
      this.startDateTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      this.saveTasks()
    },
    stopTimer() {
      if (!(this.task_name.replace(/^\s+|\s+$/gm, ''))) {
        return false
      }
      this.timerStart = false
      this.time = 0
      clearInterval(this.timer)
      this.endDateTime = moment(new Date())
        .add(this.time, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')
      this.$store.commit('app/UPDATE_MANANUAL_DISABLE_BUTTON_STATUS', false)
      this.saveTasks()
    },
    /**
     * Get Projects tasks details
     */
    async getProjectTasksData(id) {
      this.load_tickets = true
      const input = {
        project_id: id,
      }
      const response = await this.getHTTPPostResponse(
        'user-task/ticket-lists',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.tickets = data.project_tickets && data.project_tickets.length
          ? data.project_tickets
          : []
        this.load_tickets = false
      }
    },

    /**
     *save the Task Detail
     * @method Post
     * @emits {loadTaskList} {loadTaskListInDashboard}
     * @returns Save Task & task_log_id
     */
    async saveTasks() {
      this.loader = true
      const tags = []
      if (this.tags.length) {
        this.tags.forEach(element => {
          tags.push({ tag: element })
        })
      }
      const input = {
        type: 'timer',
        user_task_id: this.task_id,
        project_id: this.project_id,
        user_id: this.userInfo ? this.userInfo.id : null,
        project_task_id: this.ticket_id,
        title: this.task_name,
        description: this.task_description,
        date: moment(this.startDateTime).format('YYYY-MM-DD'),
        start_time: moment(this.startDateTime).format('HH:mm:ss'),
        end_time: this.endDateTime
          ? moment(this.endDateTime).format('HH:mm:ss')
          : null,
        tags,
        remove_tag_ids: this.edit_user_task_tags.length
          ? this.edit_user_task_tags.map(t => t.id)
          : [],
      }
      const response = await this.getHTTPPostResponse(
        'user-task/task',
        input,
        true,
      )
      if (response && response.status == 200) {
        this.loader = false
        const { data } = response

        if (data && data.task && !this.task_id) {
          this.time = 0

          this.timerStart = true
          this.timer = setInterval(() => {
            this.endDateTime = null
            this.time++
          }, 1000)
          this.task_id = data.task.id
          this.$store.commit('app/UPDATE_MANANUAL_DISABLE_BUTTON_STATUS', true)
        } else {
          this.timerStart = false
          this.time = 0
          this.timer = null
          this.task_id = null
          eventBus.$emit('loadTaskList', true)
          eventBus.$emit('loadTaskListInDashboard', true)
          this.clearTimerSectionState()
          this.clearForm()
          this.$store.state.app.dailyLogModelData = {
            task_name: null,
            project_id: null,
            ticket_id: null,
            task_date: null,
            start_time: null,
            end_time: null,
            tags: [],
          }
        }
      } else {
        this.loader = false
      }
    },

    /**
     * Clear Task Deatail
     */
    clearForm() {
      this.task_id = null
      this.startDateTime = null
      this.endDateTime = null
      this.tags = []
      this.project_id = null
      this.ticket_id = null
      this.task_name = null
      this.task_description = null
      this.edit_user_task_tags = []
      this.timerStart = false
      this.time = 0
      this.timer = null
      this.tickets = []
      /* clear value from state */
      this.$store.state.app.dailyLogModelData = {
        task_name: null,
        project_id: null,
        ticket_id: null,
        task_date: null,
        start_time: null,
        end_time: null,
        tags: [],
      }
      this.$store.commit('app/UPDATE_MANANUAL_DISABLE_BUTTON_STATUS', false)
    },

    /* to save description on blur */
    async saveTitle() {
      if (this.task_id && (this.task_name.replace(/^\s+|\s+$/gm, ''))) {
        this.loader = true
        const input = {
          user_task_id: this.task_id,
          title: this.task_name,
        }
        const response = await this.getHTTPPostResponse(
          'user-task/update-title',
          input,
          false,
        )
        if (response && response.status == 200) {
          this.loader = false
        }
        this.loader = false
      }
    },
  },
}
</script>

<style>
@import "../../../../assets/scss/component-css/timermodal.css";
</style>
<style lang="scss">
fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.form-control {
  display: block;
  padding: 0.75em;
  width: 100%;
  border: 1px solid;
  border-radius: 0.1875em;
  outline: none;
  background-color: #fff;
  transition: border-color 0.2s;
  line-height: 1;

  &:focus,
  &--focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.form-group + .form-group {
  margin-top: 1em;
}

.u-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.tagButtonCursor {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
