<template>
  <div>
    <div class="overlay" @click.prevent="closeModal()" />

    <div :id="id" class="mb-1 main-secion" :style="$store.state.app.isFullScreenModal ? 'width:35%' : null">

      <div v-if="open" class="timer-toggle">
        <div v-if="load_list" class="time-log-loader">
          <div class="time-loader-icon">
            <b-spinner label="Loading..." variant="success" />
          </div>
        </div>
        <button class="close-toggle-1 cursor-pointer"
          @click="$store.state.app.isFullScreenModal = !$store.state.app.isFullScreenModal">
          <feather-icon :icon="$store.state.app.isFullScreenModal ? 'MinimizeIcon' : 'MaximizeIcon'" size="17" />
        </button>
        <button class="close-toggle" @click.prevent="closeModal()">
          <feather-icon icon="XIcon" size="21" />
        </button>
        <!-- timer section -->

        <b-tabs v-model="$store.state.app.TimerTab">
          <b-tab class="time-tab">
            <template #title>
              <b-icon icon="Stopwatch" class="b-icon-watch" />
              <span class="timer-header-item timer">Timer </span>
              <div class="vertical-line" />
            </template>

            <timer-section :task-list="task_list" :all-tags="$store.state.app.tagList" :load-projects="loadProjects"
              :projects-data="projectsData" :tab="$store.state.app.TimerTab" />
          </b-tab>
          <!-- timer section complete -->

          <!-- menual section start-->
          <b-tab :disabled="$store.state.app.manualButtonDisble">
            <template #title>
              <feather-icon icon="EditIcon" size="18" />
              <span :class="$store.state.app.manualButtonDisble
                  ? 'submitButtonCursor timer-header-item menu'
                  : 'timer-header-item menu'
                ">Manual
              </span>
            </template>
            <menual-time :all-tags="$store.state.app.tagList" :projects-data="projectsData"
              :load-projects="loadProjects" :tab="$store.state.app.TimerTab" />
          </b-tab>
          <!-- menual section complete-->
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BSpinner } from 'bootstrap-vue'
import * as moment from 'moment/moment'
import { eventBus } from '@/main'
import TimerSection from './TimerSection.vue'
import MenualTime from './MenualTime.vue'

export default {
  name: 'TimerModal',
  components: {
    BTabs,
    BTab,
    TimerSection,
    MenualTime,
    BSpinner,
  },

  props: {
    open: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [String, undefined],
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      tabIndex: this.$store.state.app.TimerTab,
      task_list: [],
      load_list: true,
      disable_value: false,
      allTags: [],
      projectsData: [],
      loadProjects: true,
    }
  },
  created() {
    this.getTaskList()
    this.getProjectsData()
  },
  mounted() {
    eventBus.$on('loadTaskList', data => {
      if (data) {
        this.getTaskList()
      }
    })
    // edit task log
    eventBus.$on('editTaskLog', data => {
      if (data) {
        this.tabIndex = 1
      }
    })

    eventBus.$on('showExitingTaskTimer', data => {
      if (data) {
        this.$store.state.app.TimerTab = 0
      }
    })
  },

  methods: {
    closeModal() {
      eventBus.$emit('editTimesheetTask', false)
    },
    /**
     * Get Tag data
     */
    async getTagsData() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/tag',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.allTags = data.count ? data.tags : []
      }
    },
    /**
     * Get Projects data
     */
    async getProjectsData() {
      this.loadProjects = true
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'project/log-project-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.projectsData = data.count ? data.projects : []
        this.loadProjects = false
      }
    },
    /**
     * Get Projects data
     */
    async getTaskList() {
      this.load_list = true
      const current_date = moment(new Date()).format('YYYY-MM-DD')
      const input = {
        start_date: current_date,
        end_date: current_date,
        member: [this.userInfo.id],
        per_page: 1,
      }
      const response = await this.getHTTPPostResponse(
        'user-task/latest-daily-log',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.task_list = data
        if (this.taskList && this.taskList.length) {
          this.taskList.is_timer_started = true
        }
        this.load_list = false
      }
    },
  },
}
</script>

<style>
@import "../../../../assets/scss/component-css/timermodal.css";

.overlay {
  height: 100vh;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

.submitButtonCursor {
  cursor: not-allowed;
}
</style>
