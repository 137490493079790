<template>
  <div>
    <user-card
      v-if="$store.state.app.viewUserProfileId"
      :user-id="$store.state.app.viewUserProfileId"
      :modal-show="$store.state.app.isUserProfileOpen"
    />
    <p
      class="clearfix"
      style="margin-bottom: 16px"
    >
      <timer-modal
        v-if="timershow"
        :open="timershow"
        @closeTimeModal="closeTimeModal($event)"
      />
      <span class="d-flex justify-content-end ">
        <button
          v-if="showBtn == 'mastersettings'"
          class="clock-btn first-btn"
        >
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle mr-25"
          />

          <img
            src="@/assets/images/icons/foobtn.svg"
            alt="foo-btn"
          >
        </button>
        <button
          v-if="isMobile"
          class="clock-btn time-start"
          @click="logModelToggle"
        >
          <div class="d-flex align-items-center">
            <img
              src="@/assets/images/icons/clock.svg"
              alt="btn-clock"
            >
            <span class="time-foo-text">Time Log</span>
          </div>
        </button>
        <button
          v-if="!timerStart && isMobile"
          class="task"
          :class="{ 'btn-before-log-in': $store.state.app.clockInOutAnimation }"
          :disabled="loader"
          @click="startTimer()"
        >
          <div class="d-flex align-items-center">
            <feather-icon
              v-if="!loader"
              icon="PlayCircleIcon"
              class="play-icon-footer"
            />
            <b-spinner
              v-if="loader"
              small
              class="spinner"
            />
            <span class="play-icon-footer in-out-button-foo">Clock In </span>
          </div>
        </button>
        <!-- in time start button  -->
        <button
          v-if="timerStart && isMobile"
          class="task-out"
          :disabled="loader || dayInOut"
          @click="stopTimer()"
        >
          <div class="d-flex align-items-center">
            <feather-icon
              v-if="!loader"
              icon="PauseCircleIcon"
              class="play-icon-footer"
            />
            <b-spinner
              v-if="loader"
              small
              class="spinner"
            />
            <span
              class="play-icon-footer in-out-button-foo"
            >Clock out
              <p class="in-out-button-foo">{{ time | secondsInMinutes }}</p>
            </span>
          </div>
        </button>
        <span>{{ message }}</span>
        <!-- Notification dropdown -->
        <div>
          <button
            class="d-flex align-items-center dashboard-btn notificationBtn"
            @click="(notificationshow = !notificationshow), NotificationCount()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#2178fb"
              stroke="#2178fb"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-bell"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
              <path d="M13.73 21a2 2 0 0 1-3.46 0" /></svg>
            <b-badge
              v-if="notificationCount > 0"
              pill
              class="notificationBadge"
            >{{
              notificationCount
            }}</b-badge>
          </button>
          <div>
            <notification-modal
              v-if="notificationshow"
              :all-notification-data="allNotificationData"
              @NotificationRead="NotificationRead"
            />
          </div>
        </div>
      </span>
    </p>
  </div>
</template>

<script>
import { VBToggle, BBadge } from 'bootstrap-vue'
import * as moment from 'moment/moment'
import { eventBus } from '@/main'
import TimerModal from './timer/TimerModal.vue'
import notificationModal from './notificationModal/NotificationModal.vue'

export default {
  components: {
    TimerModal,
    notificationModal,
    BBadge,
  },

  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      timershow: false,
      timerStart: false,
      time: 0,
      timer: null,
      clock_id: null,
      status: null,
      startDateTime: null,
      existingIn: null,
      loader: false,
      notification_loader: false,
      dayInOut: true,
      dayInOutTimer: true,
      isMobile: false,
      items: [],
      notificationIds: [],
      totalCount: 0,
      totalNoticationCount: 0,
      notificationshow: false,
      notificationCount: 0,
      allNotificationData: null,
      message: null,
      clockInOutAnimation: false,
    }
  },
  computed: {
    showBtn: {
      get() {
        return this.$route.name ? this.$route.name : null
      },
    },
  },

  created() {
    window.addEventListener('resize', this.isMobileSize)
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobileSize)
  },
  beforeMount() {
    this.isMobileSize('before')
  },
  mounted() {
    this.clockInOut()
    this.isMobileSize('moute')
    this.NotificationCount()

    eventBus.$on('clockTimeUpdate', () => {
      clearInterval(this.timer)
      this.clockInOut()
    })
    // edit task from timesheet view
    eventBus.$on('editTimesheetTask', data => {
      if (data) {
        this.timershow = true
      } else {
        this.timershow = false
      }
    })
    eventBus.$on('closeTimeLogModal', data => {
      if (data) {
        this.timershow = false
      }
    })

    eventBus.$on('dayInOut', data => {
      this.dayInOut = data
      if (data && this.clock_id) {
        this.closeTimeModal()
        eventBus.$emit('autoTaskLogOnClockOut', true)
        this.clock_id = null
        this.timerStart = false
        this.dayInOutTimer = true

        /* variable & event bus for pass active/deactive status pass */
        const active = 'true'
        eventBus.$emit('activeStatus', active)
        /* after api call update list */
        eventBus.$emit('loadInOutList', true)
        eventBus.$emit('actionLog', true)
      }
      if (!data) {
        this.startTimer()
      }
    })
    eventBus.$on('notificationModel', data => {
      if (data) {
        this.notificationshow = true
      } else {
        this.notificationshow = false
      }
    })

    eventBus.$on('dayInOutReload', data => {
      this.dayInOut = data
    })

    eventBus.$on('reloadNotifictionData', () => {
      this.NotificationCount()
    })
  },
  updated() {
    this.isMobileSize('updated')
  },
  methods: {
    isMobileSize() {
      // var isTouchDevice = function () { return 'ontouchstart' in window || 'onmsgesturechange' in window; };
      // var isDesktop = window.screenX != 0 && !isTouchDevice() ? true : false;

      /** commented for now in small screen laptop  facing issue */
      if (window.screen.width < 560 || window.screen.height < 750) {
        this.isMobile = false
        return
      }
      const { platform } = navigator
      if (
        platform.indexOf('Mac')
        || platform.indexOf('Linux')
        || platform.indexOf('Win')
      ) {
        if (
          !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent,
          )
        ) {
          // if(isDesktop) {
          this.isMobile = true // Desktop view detected in mobile
          return
          // }
        }
      }

      this.isMobile = false
    },
    closeTimeModal() {
      this.show = false
    },
    // All notification List show
    async NotificationCount() {
      this.isMobileSize('before')
      const input = {}
      const response = await this.getHTTPPostResponse(
        'admin/master/user/notification-list',
        input,
        false,
      )
      if (response && response.data) {
        this.notificationCount = response.data.count
        this.allNotificationData = response.data
      }
    },
    // Emit event  agin notification function call
    NotificationRead() {
      this.NotificationCount()
    },
    /**
     *  Timer Start
     *
     * @returns timerstart value , set time interval ,startDateTime  & call saveData()
     */
    startTimer() {
      if (!this.dayInOut) {
        this.saveData(true)
        this.$store.state.app.clockInOutAnimation = false
      } else {
        this.setDayInOutButtonAnimation()
      }
    },
    /**
     *  Timer stop
     *
     * @returns timerstart value , clear time interval ,endDateTime & call saveData()
     */
    stopTimer() {
      this.timerStart = false
      this.time = 0
      clearInterval(this.timer)
      this.endDateTime = moment()
        .add(this.time, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')
      this.saveData()
    },
    /**
     *  User Clock In / Out
     *  Check After Reload or relogin user is in or out
     *
     * @returns timerstart value , play , clock Id
     * @emits {activeStatusAfterReload}
     */
    async clockInOut() {
      const input = {
        user_id: this.userInfo.id,
        date: moment().format('YYYY-MM-DD'),
      }
      const response = await this.getHTTPPostResponse(
        'clock-in-out/list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        data.forEach(element => {
          if (!element.out_date && !element.out_time) {
            this.time = 0
            this.startDateTime = moment(
              `${element.in_date} ${element.in_time}`,
            ).format('YYYY-MM-DD HH:mm:ss')

            // time count
            const now = moment() // todays date
            const duration = moment.duration(now.diff(this.startDateTime))
            this.time = duration.asSeconds().toFixed(0)
            this.timer = setInterval(() => {
              this.time++
            }, 1000)

            /*  display icon */
            this.timerStart = true
            this.play = true
            this.clock_id = element.id
            this.dayInOutTimer = false

            /* variable & event bus for pass active/deactive status pass */
            eventBus.$emit('activeStatusAfterReload', 'true')
          }
        })
      }
    },
    /**
     *  Save In/Out Data
     *  on Save In/out log call
     * @returns data(variable),clockId,
     * @emits {activeStatus},{loadInOutList},{actionLog}
     *
     */
    async saveData() {
      this.loader = true
      if (!this.clock_id) {
        clearInterval(this.timer)
      }
      const input = {
        clock_id: this.clock_id ? this.clock_id : null,
        type: 'manual',
        in_time: !this.clock_id ? moment().format('HH:mm:ss') : null,
        in_date: !this.clock_id
          ? moment().format('YYYY-MM-DD')
          : null,
        out_time: this.clock_id ? moment().format('HH:mm:ss') : null,
        out_date: this.clock_id
          ? moment().format('YYYY-MM-DD')
          : null,
        status: this.clock_id ? 'out' : 'in',
      }

      const response = await this.getHTTPPostResponse(
        '/clock-in-out',
        input,
        true,
      )
      if (response && response.status == 200) {
        const { data } = response.data
        if (!this.clock_id) {
          this.startDateTime = moment(`${data.in_date} ${data.in_time}`).format(
            'YYYY-MM-DD HH:mm:ss',
          )
          // time count
          const now = moment() // todays date
          const duration = moment.duration(now.diff(this.startDateTime))

          this.time = duration.asSeconds().toFixed(0)
          this.timer = setInterval(() => {
            this.time++
          }, 1000)

          /*  display icon */
          this.timerStart = true
          this.play = true
          this.clock_id = data.id
          this.dayInOutTimer = false
        } else {
          eventBus.$emit('autoTaskLogOnClockOut', true)
          this.clock_id = null
          this.time = 0
          this.timerStart = false
          this.dayInOutTimer = true
        }
        /* variable & event bus for pass active/deactive status pass */
        const active = data && data.in_time ? 'true' : 'false'
        eventBus.$emit('activeStatus', active)
        /* after api call update list */
        eventBus.$emit('loadInOutList', true)
        eventBus.$emit('actionLog', true)
        setTimeout(() => {
          this.loader = false
        }, 1500)
      } else {
        this.loader = false
      }
    },

    logModelToggle() {
      if (this.dayInOutTimer && this.dayInOut) {
        this.setDayInOutButtonAnimation()
      } else if (!this.dayInOutTimer) {
        this.timershow = !this.timershow
      } else {
        this.$store.state.app.clockInOutAnimation = true
      }
    },

    setDayInOutButtonAnimation() {
      // If not DayIn and click on time Log / Clock In button then open sidemenu bar with dayInOut button animation
      this.$store.state.app.dayInOutBtnAnimation = true
      const mainMenu = document.querySelector('.main-menu')
      mainMenu.classList.add('expanded')
    },
  },
}
</script>
