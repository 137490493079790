<template>
  <!-- <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded: isMouseHovered,
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  > -->
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
    {
      expanded:
        !isVerticalMenuCollapsed ||
        (isVerticalMenuCollapsed && isMouseHovered),
    },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
  ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <!-- <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link> -->
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
      @ps-scroll-y="(evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
        ">
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
    <footer class="p-0">
      <slot name="footer">
        <div v-if="userInfo.role === 'EMP' && userInfo.is_resource_managing != 1 && pendingTimesheet.length"
          class="pendingTimeSheetBtn">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="timesheetBtn" :disabled="loader"
            @click="submitTimeSheet()">
            <span>Pending Timesheet:</span> {{ pendingTimesheet.length }}
          </b-button>
        </div>
        <div v-if="isMobile" class="dayinoutBtn" :class="$store.state.app.dayInOutBtnAnimation ? 'before-day-in' : ''">
          <b-button v-if="!day_id" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="dayinBtn"
            :disabled="loader" @click="saveData()">
            <img src="../../../../../../src/assets/images/icons/dayin.svg" alt="dayin">
            <span>Day In</span>
          </b-button>
          <b-button v-else v-b-modal.dayinoutPopup v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
            class="dayinBtn" :disabled="loader" @click="summaryOpen()">
            <img src="../../../../../../src/assets/images/icons/dayout.svg" alt="dayout">
            <span>Day Out</span>
          </b-button>
          <b-button v-if="day_id" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="dayinTime">
            <span>Day in at {{ firstDayIn }}.</span>
          </b-button>
        </div>
        <DayInOut />

        <div class="hr-line" />
        <!-- <div class="d-flex align-items-center"> -->
        <div class="d-flex justify-content-between align-items-center">
          <b-dropdown toggle-class="d-flex align-items-center dropdown-user-link" class="userprofile-dropdown p-0"
            dropright>
            <template #button-content>
              <!-- <b-avatar
              class="user-foo-profile"

              badge
              :badge-variant="status == 'true'? 'success' :'secondary'"
              :src="require('@/assets/images/portrait/small/avatar-s-16.jpg')"
            /> -->
              <!-- <b-avatar
              class="user-foo-profile"
              badge-variant="secondary"
              badge
              :src="require('@/assets/images/portrait/small/avatar-s-16.jpg')"
            /> -->

              <!-- if user profile not avalible then show -->
              <!-- <b-avatar
              badge
              variant="primary"
              class="user-foo-profile"
              badge-variant="success"
            >
              <span class="avtar-font-profile">Hi</span>
            </b-avatar> -->
              <b-avatar v-b-tooltip.hover.top badge variant="primary" class="user-foo-profile"
                :src="userInfo.profile_pic" :text="(userInfo.name + ' ' + userInfo.last_name) | avatarText"
                :badge-variant="status == 'true' ? 'success' : 'secondary'"
                :title="userInfo.name + ' ' + userInfo.last_name">
                <!-- <span class="avtar-font-profile">{{
                userInfo.name.substring(0, 2)
              }}</span> -->
              </b-avatar>
            </template>

            <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
              <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
              <span>Log out</span>
            </b-dropdown-item>
          </b-dropdown>
          <p class="mr-2">
            V{{ version }}
          </p>
        </div>
      </slot>
    </footer>
  </div>
</template>

<script>
// import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import * as moment from 'moment/moment'
import { eventBus } from '@/main'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import DayInOut from '../../../../../components/dayinout/DayInOut.vue'
import pack from '../../../../../../package.json'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    DayInOut,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      status: false,
      dayInOut: false,
      timerStart: false,
      time: 0,
      timer: null,
      day_id: null,
      startDateTime: null,
      existingIn: null,
      firstDayIn: null,
      loader: false,
      productive_hours: null,
      productivity: null,
      isMobile: false,
      pendingTimesheet: [],
      version: pack.version, /// /6.4.0
    }
  },
  created() {
    window.addEventListener('resize', this.isMobileSize)
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobileSize)
  },
  beforeMount() {
    this.isMobileSize()
  },
  mounted() {
    this.isMobileSize()
    this.dayInOuts()
    this.isUserHaveProjectNotification()
    /* get active status  on click in out button */
    eventBus.$on('activeStatus', data => {
      if (data) {
        this.status = data
      }
    })

    /* get active status  on click in out button */
    eventBus.$on('Outday', data => {
      if (data) {
        (this.productive_hours = data.productive_hours),
          (this.productivity = data.productivity)
        this.stopTimer()
      }
    })

    /* get active status  on load */
    eventBus.$on('activeStatusAfterReload', data => {
      //
      if (data) {
        this.status = data
      }
    })

    /* get active status  on click in out button */
    eventBus.$on('isNotification', data => {
      if (data) {
        this.isUserHaveProjectNotification()
      }
    })

    /**
     * Get Pending Timesheet weeks
    */
    eventBus.$on('reloadPendingTimesheetData', () => {
      this.getPendingWeek()
    })
    this.getPendingWeek()
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  updated() {
    this.isMobileSize()
  },
  methods: {
    isMobileSize() {
      // var isTouchDevice = function () { return 'ontouchstart' in window || 'onmsgesturechange' in window; };
      // var isDesktop = window.screenX != 0 && !isTouchDevice() ? true : false;

      /** commented for now in small screen laptop  facing issue */
      if (window.screen.width < 560 || window.screen.height < 750) {
        this.isMobile = false
        return
      }
      const { platform } = navigator
      if (
        platform.indexOf('Mac')
        || platform.indexOf('Linux')
        || platform.indexOf('Win')
      ) {
        if (
          !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent,
          )
        ) {
          // if(isDesktop) {
          this.isMobile = true // Desktop view detected in mobile
          return
          // }
        }
      }

      this.isMobile = false
    },

    summaryOpen() {
      eventBus.$emit('summaryOpen', true)
    },
    /**
     *  User dayInout In / Out
     *  Check After Reload or relogin user is in or out
     */
    async dayInOuts() {
      const input = {
        user_id: this.userInfo.id,
        date: moment(new Date()).format('YYYY-MM-DD'),
      }
      const response = await this.getHTTPPostResponse(
        'day-in-out/list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response

        if (data && data.length) {
          this.firstDayIn = moment(
            `${input.date} ${data[data.length - 1].day_in}`,
          ).format('h:mm a')

          if (!data[0].day_out) {
            this.dayInOut = true
            this.time = 0
            this.startDateTime = moment(
              `${data[0].date} ${data[0].day_in}`,
            ).format('YYYY-MM-DD HH:mm:ss')

            // time count
            const now = moment(new Date()) // todays date

            const duration = moment.duration(now.diff(this.startDateTime))
            this.time = duration.asSeconds().toFixed(0)
            this.timer = setInterval(() => {
              this.time++
            }, 1000)

            /*  display icon */
            this.timerStart = true
            this.play = true
            this.day_id = data[0].id

            /* variable & event bus for pass active/deactive status pass */
            eventBus.$emit('dayInOutReload', false)
          }
        }
      }
    },

    /**
     *  Timer Start
     *
     * @returns timerstart value , set time interval ,startDateTime  & call saveData()
     */
    async startTimer() {
      this.saveData(true)
    },
    /**
     *  Timer stop
     *
     * @returns timerstart value , clear time interval ,endDateTime & call saveData()
     */
    stopTimer() {
      this.timerStart = false
      this.time = 0
      clearInterval(this.timer)
      this.endDateTime = moment(new Date())
        .add(this.time, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss')
      this.saveData()
    },

    /** this.summaryLoader = false;
     *  Save In/Out Data
     *  on Save In/out log call
     *
     */
    async saveData() {
      this.loader = true
      const input = {
        day_id: this.day_id ? this.day_id : null,
        user_id: this.userInfo.id,
        type: 'manual',
        day_in: !this.day_id ? moment(new Date()).format('HH:mm:ss') : null,
        date: moment(new Date()).format('YYYY-MM-DD'),
        day_out: this.day_id ? moment(new Date()).format('HH:mm:ss') : null,
        status: this.day_id ? 'out' : 'in',
        productive_hours: this.day_id
          ? this.productive_hours
          : null /* minutes */,
        productivity: this.day_id ? this.productivity : null /* percentage */,
        additionalDetail: this.$store.state.app.dayOutWithAdditionalDetail,
        sendMail: this.$store.state.app.dayOutWithSendMail == 'yes',
      }

      const response = await this.getHTTPPostResponse('/day-in-out', input, true)
      if (response && response.status == 200) {
        const { data } = response.data
        if (!this.day_id) {
          this.startDateTime = moment(`${data.date} ${data.day_in}`).format(
            'YYYY-MM-DD HH:mm:ss',
          )
          // time count
          const now = moment(new Date()) // todays date
          const duration = moment.duration(now.diff(this.startDateTime))
          this.time = duration.asSeconds().toFixed(0)
          this.timer = setInterval(() => {
            this.time++
          }, 1000)
          //

          this.firstDayIn = this.firstDayIn
            ? this.firstDayIn
            : moment(`${input.date} ${data.day_in}`).format('h:mm a')

          /*  display icon */
          this.timerStart = true
          this.play = true
          this.day_id = data.id
          this.dayInOut = true
          this.$store.state.app.dayInOutBtnAnimation = false
        } else {
          this.day_id = null
          this.time = 0
          this.timerStart = false
          this.dayInOut = false
          this.$store.state.app.clockInOutAnimation = false
        }
        /* variable & event bus for pass active/deactive status pass */

        const active = !this.day_id
        eventBus.$emit('dayInOut', active)
        eventBus.$emit('dayInOutTaskTimeLogClose', active)

        setTimeout(() => {
          this.loader = false
          this.$store.state.app.dayOutWithSendMail = null
          this.$store.state.app.dayOutWithAdditionalDetail = null
        }, 1000)
      } else {
        this.loader = false
      }
    },

    /*
    * login user have notification count
    */
    async isUserHaveProjectNotification() {
      const response = await this.getHTTPPostResponse(
        'admin/master/user/project-notification-list',
        {},
        false,
      )
      if (response && response.status === 200) {
        const projectIndex = this.navMenuItems.findIndex(e => e.title === 'Projects')
        if (projectIndex > -1) {
          if (response.data && response.data.count && response.data.count > 0) {
            this.$store.state.app.isProjectNotification = true
          } else {
            this.$store.state.app.isProjectNotification = false
          }
        }
      }
    },

    /**
     * Get Pending timesheet week user data date
     */
    async getPendingWeek() {
      const input = {}
      const response = await this.getHTTPPostResponse(
        'admin/master/timesheet/get-timesheet-week',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.pendingTimesheet = data
      }
    },
    /**
     * Submit timeSheet
     */
    async submitTimeSheet() {
      const start_date = this.pendingTimesheet && this.pendingTimesheet.length ? this.pendingTimesheet[0].start_date : null
      const end_date = this.pendingTimesheet && this.pendingTimesheet.length ? this.pendingTimesheet[0].end_date : null
      if (start_date && end_date) {
        if (this.$route.name == 'timeSheetView') {
          const obj = {
            startDate: start_date,
            endDate: end_date,
          }
          eventBus.$emit('timeSheetDateSet', obj)
        } else {
          this.$router.push({
            name: 'timeSheetView',
            query: { startDate: start_date, endDate: end_date },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.userprofile-dropdown {
  .dropdown-menu {
    top: 10px !important;
    left: -10px !important;
  }
}

.userprofile-dropdown .dropdown-item {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #2178fb;
  background-color: $white !important;
  padding: 12px 15px;
  border-radius: 3px;

  &:hover {
    background-color: rgba(33, 120, 251, 0.2) !important;
    color: #2178fb;
  }
}


</style>
