<template>
  <div>
    <div class="time-tracker">
      <!-- select box for select project and ticket -->
      <validation-observer
        ref="manualTaskRules"
        v-slot="{ invalid }"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="select-project"
            >
              <b-overlay
                :show="load_projects"
                rounded="sm"
              >
              <!-- #default="{ errors }" -->
                <validation-provider
                  name="Project"
                  rules="required"
                >
                  <v-select
                    id="project_id"
                    v-model="project_id"
                    name="project_id"
                    label="name"
                    :options="projects"
                    :reduce="(project) => project.id"
                    class="select-project-ticket"
                    placeholder="Select Project *"
                    :disabled="task_log_id ? true : false"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </validation-provider>
              </b-overlay>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="select-ticket"
            >
              <b-overlay
                :show="load_tickets"
                rounded="sm"
              >
              <!-- #default="{ errors }" -->
                <validation-provider
                  name="Ticket"
                  rules=""
                >
                  <v-select
                    id="ticket_id"
                    v-model="ticket_id"
                    hide-details
                    name="ticket_id"
                    label="number"
                    :options="tickets"
                    :reduce="(ticket) => ticket.id"
                    class="select-project-ticket"
                    placeholder="Select Ticket"
                    :disabled="task_log_id ? true : false"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </validation-provider>
              </b-overlay>
            </b-col>
          </b-row>
          <b-form-group class="mb-0">
            <validation-provider
              #default="{ errors }"
              name="Task"
              rules="required"
            >
              <b-form-textarea
                v-model="task_name"
                class="select-item text-area time-log-text-area"
                name="task_name"
                type="text"
                :rows="$store.state.app.isFullScreenModal ?12 :3"
                maxlength="2000"
                placeholder="Describe your task details in max 2000 character *"
                :disabled="task_log_id ? true : false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <p class="date-duration">
            <span class="date-duration-data"> When: </span>
            <span>
              <flat-pickr
                v-model="task_date"
                class="timer-toggle-form"
                :config="configTaskDate"
                name="task_date"
              />
            </span>
          </p>
          <b-row v-if="!task_id">
            <b-col
              cols="12"
              md="6"
              class="select-project"
            >
              <b-form-group class="mb-0">
                <validation-provider
                  #default="{ errors }"
                  name="Start time"
                  rules="required"
                >
                  <b-form-input
                    id="myInput"
                    v-model="start_time"
                    class="select-time"
                    :style="$store.state.app.isFullScreenModal ? 'text-align: start !important;':null"
                    name="start_time"
                    type="time"
                    placeholder="03:29 PM"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="select-ticket"
            >
              <b-form-group class="mb-0">
                <validation-provider
                  #default="{ errors }"
                  name="End time"
                  rules="required"
                >
                  <b-form-input
                    v-model="end_time"
                    class=" select-time"
                    :style="$store.state.app.isFullScreenModal ? 'text-align: start !important;':null"
                    name="end_time"
                    type="time"
                    placeholder="03:29 PM"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="d-flex m-0">
            <div
              v-for="(tag, tindex) in tags"
              :key="tindex"
              class="tag-timer-modal"
            >
              <span class="tag-timer-modal-span">{{ tag }}</span>
              <feather-icon
                icon="XIcon"
                size="10"
                class="x-icon-tag"
                @click="tags.splice(tindex, 1)"
              />
            </div>
          </b-row>

          <!-- input tag for eneter task -->
        </b-form>
        <div class="timer-icons menual-time">
          <div class="d-flex align-items-center">
            <div class="save-btn-timer">
              <div class="icons">
                <b-button
                  type="submit"
                  :disabled="invalid || !checkTimeValid || btnloader"
                  @click.prevent="saveTasks()"
                >
                  Save
                </b-button>
              </div>
            </div>
            <div class="clear-button-timer">
              <div class="icons">
                <b-button
                  type="submit"
                  @click.prevent="clearForm()"
                >
                  Clear
                </b-button>
              </div>
            </div>
          </div>
          <div class="feather-icon">
            <div class="icons">
              <custom-dropdown
                v-model="tags"
                :options="tagOptions"
                icon="TagIcon"
                multiple
                class="custom-drop"
              />
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import * as moment from 'moment/moment'
import { eventBus } from '@/main'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import FeatherIcon from '../../../components/feather-icon/FeatherIcon.vue'

export default {
  name: 'MenualTime',
  components: {
    vSelect,
    flatPickr,
    FeatherIcon,
    ValidationProvider,
    ValidationObserver,
    customDropdown,
  },
  props: {
    allTags: {
      type: Array,
      required: true,
      default: () => [],
    },
    projectsData: {
      type: Array,
      required: true,
      default: () => [],
    },
    loadProjects: {
      type: Boolean,
      required: true,
      default: false,
    },
    tab: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  
  data() {
    return {
      task_id: null,
      task_log_id: null,
      tickets: [],
      projects: [],
      all_tags: [],
      tags: (this.$store.state.app.dailyLogModelData && this.$store.state.app.dailyLogModelData.tags) || [],
      project_id: (this.$store.state.app.dailyLogModelData && this.$store.state.app.dailyLogModelData.project_id) || null,
      ticket_id: (this.$store.state.app.dailyLogModelData && this.$store.state.app.dailyLogModelData.ticket_id) || null,
      task_name: (this.$store.state.app.dailyLogModelData && this.$store.state.app.dailyLogModelData.task_name) || null,
      task_description: null,
      start_time: this.$store.state.app.dailyLogModelData.start_time || null,
      end_time: this.$store.state.app.dailyLogModelData.end_time || null,
      task_date: this.$store.state.app.dailyLogModelData.task_date || null,
      configTaskDate: {
        minDate: null,
        maxDate: new Date(),
        dateFormat: 'd-m-Y',
      },
      load_tickets: false,
      load_projects: false,
      edit_user_task_tags: [],
      searchOption: null,
      btnloader: false,
    }
  },
  computed: {
    tagOptions() {
      const tags = []
      this.$store.state.app.tagList.forEach(element => {
        tags.push({
          value: element.name,
          label: element.name,
        })
      })
      return tags
    },
    allTagOptions() {
      if (this.searchOption) {
        const that = this
        return this.all_tags.filter(element => element.name
          .toLowerCase()
          .match(that.searchOption.toLowerCase()))
      }
      return this.all_tags
    },
    checkTimeValid() {
      if (this.start_time && this.end_time) {
        if (
          Date.parse(`01/01/2022 ${this.end_time}:00`)
          <= Date.parse(`01/01/2022 ${this.start_time}:00`)
        ) {
          this.$bvToast.toast('Please select right time.', {
            toaster: 'b-toaster-top-right',
            solid: true,
            title: 'End time is not valid',
            variant: 'danger',
          })
          return false
        }
        return true
      }
      return false
    },
  },
  watch: {
    project_id(nv, old) {
      if (nv) {
        this.getProjectTasksData(nv)
        this.$store.state.app.dailyLogModelData.project_id = nv
      }
      if (nv && old) {
        this.ticket_id = null
      }
    },
    // All Tag Data with props
    allTags(val) {
      this.all_tags = val
    },
    // All project Data with props
    projectsData(val) {
      this.projects = val
    },
    // loadProjects true and false
    loadProjects(val) {
      this.load_projects = val
    },
    ticket_id(nv) {
      this.$store.state.app.dailyLogModelData.ticket_id = nv
    },
    task_name(nv) {
      this.$store.state.app.dailyLogModelData.task_name = nv
    },
    tags(nv) {
      this.$store.state.app.dailyLogModelData.tags = nv
    },
    end_time(nv) {
      this.$store.state.app.dailyLogModelData.end_time = nv
    },
    start_time(nv) {
      this.$store.state.app.dailyLogModelData.start_time = nv
    },
    task_date(nv) {
      this.$store.state.app.dailyLogModelData.task_date = nv
    },
    tab() {
      this.project_id = this.$store.state.app.dailyLogModelData.project_id
      this.task_name = this.$store.state.app.dailyLogModelData.task_name
      this.ticket_id = this.$store.state.app.dailyLogModelData.ticket_id
      this.tags = this.$store.state.app.dailyLogModelData.tags

      eventBus.$emit('closeCustomDropDown')
    },
  },
  mounted() {
    if (this.$store.state.app.dailyLogModelData.project_id) {
      this.getProjectTasksData(this.$store.state.app.dailyLogModelData.project_id)
    }
    this.task_date = this.task_date || moment(new Date()).format('DD-MM-YYYY')
    // edit task log
    eventBus.$on('editTaskLog', data => {
      if (data) {
        setTimeout(() => {
          this.setEditData(data)
        }, 900)
      }
    })
    eventBus.$on('loadTaskList', data => {
      if (data) {
        this.clearForm()
      }
    })
  },
  methods: {
    /**
     * Get Tasks data
     */
    /**
     * Get Tasks data
     * @param {data}
     * @returns  set modal variable and list of tags
     */
    setEditData(data) {
      //
      this.ticket_id = data.ticket_id
      this.project_id = data.project_id
      this.task_name = data.task_name
      this.task_id = !data.task_log_id ? data.task_id : null
      this.task_log_id = data.task_log_id ? data.task_log_id : null
      this.task_date = data.date ? data.date : null
      this.start_time = data.start_time ? data.start_time : null
      this.end_time = data.end_time ? data.end_time : null
      if (data.user_task_tags && data.user_task_tags.length) {
        const tags = []
        data.user_task_tags.forEach(tag => {
          tags.push(tag.tag)
        })
        this.tags = tags
        this.edit_user_task_tags = data.user_task_tags
      }
    },
    /**
     * Get Projects tasks details
     */
    async getProjectTasksData(id) {
      this.load_tickets = true
      const input = {
        project_id: id,
      }
      const response = await this.getHTTPPostResponse(
        'user-task/ticket-lists',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.tickets = data.project_tickets && data.project_tickets.length
          ? data.project_tickets
          : []
        this.load_tickets = false
      }
    },

    /**
     *validate form
     * @returns if task_log_id then call  updateTimeInterval() else check task_id exist then call updateTasks() else saveTasks()
     */
    validationForm() {
      this.$refs.manualTaskRules.validate().then(success => {
        if (success) {
          this.saveTasks()
        }
      })
    },

    async saveTasks() {
      this.btnloader = true
      const tags = []
      if (this.tags.length) {
        this.tags.forEach(element => {
          tags.push({ tag: element })
        })
      }

      const input = {
        type: 'manual',
        user_task_id: this.task_id,
        project_id: this.project_id,
        user_id: this.userInfo ? this.userInfo.id : null,
        project_task_id: this.ticket_id,
        title: this.task_name,
        description: this.task_description,
        date: moment(this.task_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        start_time: moment(
          `${moment(this.task_date, 'DD-MM-YYYY').format('YYYY-MM-DD')}T${
            this.start_time
          }`,
        ).format('HH:mm:ss'),
        end_time: moment(
          `${moment(this.task_date, 'DD-MM-YYYY').format('YYYY-MM-DD')}T${
            this.end_time
          }`,
        ).format('HH:mm:ss'),
        tags,
        remove_tag_ids: this.edit_user_task_tags.length
          ? this.edit_user_task_tags.map(t => t.id)
          : [],
      }
      const response = await this.getHTTPPostResponse(
        'user-task/task',
        input,
        true,
      )
      if (response && response.status == 200) {
        setTimeout(() => {
          this.clearTimerSectionState()
          this.clearForm()
          this.$store.state.app.TimerTab = 0
          eventBus.$emit('loadTaskList', true)
          eventBus.$emit('loadTaskListInDashboard', true)
          eventBus.$emit('closeTimeLogModal', true)
          this.$store.state.app.dailyLogModelData = {
            task_name: null,
            project_id: null,
            ticket_id: null,
            task_date: null,
            start_time: null,
            end_time: null,
            tags: [],
          }
          this.$store.state.app.TimerTab = 0
          this.btnloader = false
        }, 500)
      } else {
        this.btnloader = false
      }
    },

    /**
     *update task details
     * @method PUT
     * @emits {loadTaskList}
     * @returns Update Task
     */
    async updateTimeInterval() {
      const input = {
        log_id: this.task_log_id,
        date: this.task_date,
        start_time: moment(`${this.task_date}T${this.start_time}`).format(
          'HH:mm:ss',
        ),
        end_time: moment(`${this.task_date}T${this.end_time}`).format(
          'HH:mm:ss',
        ),
      }
      const response = await this.getHTTPPutResponse(
        'user-task/task/timeinterval',
        input,
        true,
      )
      if (response && response.status == 200) {
        this.clearForm()
        this.timerStart = false
        this.time = 0
        this.timer = null
        eventBus.$emit('loadTaskList', true)
      }
    },

    /**
     * update timer details
     * @method PUT
     * @emits {loadTaskList} {editTimesheetTask}
     * @returns Update Task
     */
    async updateTasks() {
      const tags = []
      if (this.tags.length) {
        this.tags.forEach(element => {
          tags.push({ tag: element })
        })
      }
      const input = {
        project_id: this.project_id,
        task_status_code: 'CT',
        project_task_id: this.ticket_id,
        title: this.task_name,
        description: this.task_description,
        tags,
        date: this.task_date,
        remove_tag_ids: this.edit_user_task_tags.length
          ? this.edit_user_task_tags.map(t => t.id)
          : [],
      }
      const response = await this.getHTTPPutResponse(
        `user-task/task/update/${this.task_id}`,
        input,
        true,
      )
      if (response && response.status == 200) {
        this.clearForm()
        eventBus.$emit('loadTaskList', true)
        eventBus.$emit('editTimesheetTask', false)
      }
    },
    /**
     * Clear Task Deatail
     */
    clearForm() {
      this.task_id = null
      this.start_time = null
      this.end_time = null
      this.tags = []
      this.project_id = null
      this.ticket_id = null
      this.task_name = null
      this.task_description = null
      this.task_log_id = null
      this.tickets = []
      this.task_date = moment(new Date()).format('DD-MM-YYYY')
      // reset form
      this.$refs.manualTaskRules.reset()
    },
  },
}
</script>

<style>
@import "../../../../assets/scss/component-css/timermodal.css";
.timer-modal
 .vs--disabled .vs__clear,
 .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__open-indicator,
 .vs--disabled .vs__search,
 .vs--disabled .vs__selected {
  background-color: unset !important;
}

.display-time {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}
.dropdown-toggle {
  border: none;
}
</style>
